import customAxios from "@util/ajax/AxiosManager";

class SocialMemberData {
    constructor(id, nickname, profile=null, accessToken=null, refreshToken=null) {
        this.id = id;
        this.nickname = nickname;
        this.profile = profile;
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }
}
export {SocialMemberData};

const getKakaoUserData = async (accessToken)=>{
    if(accessToken === undefined) return null;
    const {data} = await customAxios.post("https://kapi.kakao.com/v2/user/me", {}, {
        headers:{
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/x-www-form-urlencoded;charset=utf-8"
        }
    });
    return new SocialMemberData(data.id, data.properties.nickname);
};
export {getKakaoUserData};