//css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/cosmo/bootstrap.min.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {ContextCustomProvider} from '@util/store/context';
import library from '@util/icon/font-awesome';
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
  {/* <HashRouter basename={process.env.REACT_APP_PUBLIC_URL}> */}
    {/* <ContextCustomProvider> */}
      <RecoilRoot>
          <App />
      </RecoilRoot>
    {/* </ContextCustomProvider> */}
  {/* </HashRouter> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
