import { atom, selector } from "recoil";

//회원 정보
const userInfoState = atom({
    key:"userInfoState",
    default:null,
});
export {userInfoState};

//로그인 완료 플래그
const userLoginFlagState = atom({
    key:"userLoginFlagState",
    default:false,
});
export {userLoginFlagState};

//로그인 상태 판정
const loginState = selector({
    key:"loginState",
    get:(state)=>{
        const userInfo = state.get(userInfoState);
        return userInfo !== null;
    }
});
export {loginState};
