import './App.css';
import '@component/fragment/loader/LoadingScreen.css';

import {Routes, Route} from 'react-router';

import {Container, Row, Col} from 'react-bootstrap';

import MainHeader from '@component/template/MainHeader';
import MainFooter from '@component/template/MainFooter';

import { useRecoilState, useRecoilValue } from "recoil";
import { loginState, userInfoState, userLoginFlagState } from "./utils/recoil";

import customAxios from '@util/ajax/AxiosManager';
import { getKakaoUserData } from "./utils/social/SocialUserInfo";
import { lazy, Suspense, useCallback, useEffect } from 'react';
import useTitle from '@util/title/useTitle';
import ClimbingBoxLoader from "react-spinners/esm/ClimbingBoxLoader";

//import PublicRoute from '@util/router/PublicRoute';
const PublicRoute = lazy(()=>import("@util/router/PublicRoute"));
const MemberRoute = lazy(()=>import("@util/router/MemberRoute"));
const MainContent = lazy(()=>import("@component/screen/MainContent"));
const PortfolioList = lazy(()=>import('@component/screen/portfolio/PortfolioList'));
const QuizList = lazy(()=>import('@component/screen/quiz/QuizList'));
const LinkList = lazy(()=>import('@component/screen/link/LinkList'));
const NoticeBoardList = lazy(()=>import('@component/screen/notice/NoticeBoardList'));
const DevelopInfo = lazy(()=>import('@component/screen/develop/DevelopInfo'));
const MemberLoginPage = lazy(()=>import('@component/screen/member/MemberLoginPage'));
const PageNotFound = lazy(()=>import('@component/screen/error/PageNotFound'));
const AccessDenied = lazy(()=>import('@component/screen/error/AccessDenied'));

const MemberDetailPage = lazy(()=>import('@component/screen/member/MemberDetailPage'));
const AdminHome = lazy(()=>import('@component/screen/admin/AdminHome'));

const App = ()=>{
  const changeTitle = useTitle();
  useEffect(()=>changeTitle("H'academy"), []);

  //recoil
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [userLoginFlag, setUserLoginFlag] = useRecoilState(userLoginFlagState);
  useEffect(()=>{
    const refreshToken = window.localStorage.getItem("refreshToken");
    if(refreshToken === null) return;
    refreshKakaoLogin(refreshToken);
  }, []);

  const refreshKakaoLogin = useCallback(async (refreshToken)=>{
    if(refreshToken === undefined) return;

    const response = await customAxios.post(
      "https://kauth.kakao.com/oauth/token",
      {
        "grant_type": "refresh_token",
        "client_id": process.env.REACT_APP_KAKAO_CLIENT_ID,
        "refresh_token": refreshToken,
      },
      {
        headers: {
          "Content-Type":"application/x-www-form-urlencoded",
        }
      }
    );
    //토큰으로 회원정보 가져오기
    const userData = await getKakaoUserData(response.data.access_token);
    userData.accessToken = response.data.access_token;
    userData.refreshToken = refreshToken;
    if(userData !== null) {
      setUserInfo(userData);
      //refresh_token은 1개월 미만일 경우만 갱신되어 지급됨
      window.localStorage.setItem("accessToken", response.access_token);
      if(response.refresh_token) {
        userData.refreshToken = response.refresh_token;
        window.localStorage.setItem("refreshToken", response.refresh_token);
      }
    }

    setUserLoginFlag(true);
  }, []);

  //view
  return (
    <>
      <MainHeader/>
      <Container fluid className="mt-5 mb-5">
        <Row className="mt-4"></Row>
        <Row className="mt-4"></Row>
        <Row className="mt-4"></Row>
        <Row>
          <Col lg={ {span:10, offset:1} }> 
            <Suspense fallback={<ClimbingBoxLoader/>}>
            <Routes>
              <Route path='/' element={<PublicRoute><MainContent/></PublicRoute>}/>
              <Route path='/portfolio' element={<PublicRoute><PortfolioList/></PublicRoute>}/>
              <Route path='/quiz' element={<QuizList/>}/>
              <Route path='/link' element={<PublicRoute><LinkList/></PublicRoute>}/>
              <Route path='/notice' element={<PublicRoute><NoticeBoardList/></PublicRoute>}/>
              <Route path='/developer' element={<PublicRoute><DevelopInfo/></PublicRoute>}/>
              <Route path='/login/user' element={<PublicRoute restricted={true}><MemberLoginPage/></PublicRoute>}/>
              <Route path='/member' element={<MemberRoute><MemberDetailPage/></MemberRoute>}></Route>
              <Route path="/admin/*" element={<MemberRoute needAdmin={true}><AdminHome/></MemberRoute>}/>
              <Route path="/access-denied" element={<AccessDenied/>}/>
              <Route path='*' element={<PublicRoute><PageNotFound/></PublicRoute>}/>
            </Routes> 
            </Suspense>
          </Col>
        </Row>
      </Container>
      <MainFooter/>

    </>
  );
}

export default App;
