import './MainHeader.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState, useRecoilValue } from "recoil";
import { loginState, userInfoState, userLoginFlagState } from "@util/recoil";
import { useCallback } from "react";
import customAxios from "@util/ajax/AxiosManager";

const LoginNavLink = props => {
    //util
    const navigate = useNavigate();

    //recoil
    const isLogin = useRecoilValue(loginState);
    const userLoginFlag = useRecoilValue(userLoginFlagState);
    const [userInfo, setUserInfo] = useRecoilState(userInfoState);

    //로그아웃
    const logout = useCallback(async (e)=>{
        e.preventDefault();
        try {
            await customAxios.post("https://kapi.kakao.com/v1/user/logout", {}, {
                headers:{
                    Authorization: `Bearer ${userInfo.accessToken}`
                }
            });
        }
        catch(e){}
        finally {
            setUserInfo(null);
            window.localStorage.removeItem("accessToken");
            window.localStorage.removeItem("refreshToken");
        }
    }, [userInfo]);

    if (userLoginFlag && isLogin) {
        return (
        <>
            {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>관리 도구</Tooltip>}> */ }
                {/* <Nav.Link className="ms-2 text-end" as={Link} to="/admin"> */ }
                    {/* <FontAwesomeIcon icon="fa-solid fa-gear" /> */ }
                {/* </Nav.Link> */ }
            {/* </OverlayTrigger> */ }
            {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>내 정보</Tooltip>}> */ }
                <Nav.Link className="ms-2 text-end" as={Link} to="/member">
                    {/* {userInfo.profile ? (
                    <img src={'https://picsum.photos/100'} alt={`프로필 이미지`} width={16} height={16} className="rounded-circle border-light profile-image"/>
                    ) : (
                    <FontAwesomeIcon icon="fa-solid fa-user"/>
                    )} */}
                    {userInfo.nickname} 님
                </Nav.Link>
            {/* </OverlayTrigger> */ }
            {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>로그아웃</Tooltip>}> */ }
                <Nav.Link className="ms-2 text-end" as={Link} to="#" onClick={logout}>
                    <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
                </Nav.Link>
            {/* </OverlayTrigger> */ }
        </>
        );
    }
    else {
        return (
            <>
                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>로그인</Tooltip>}> */}
                <Nav.Link className="ms-2 text-end" as={Link} to="/login/user">
                    <FontAwesomeIcon icon="fa-solid fa-right-to-bracket" />
                </Nav.Link>
                {/* </OverlayTrigger> */}
            </>
        );
    }
};

const MainHeader = props => {
    //const navigate = useNavigate();
    const location = useLocation();
    return (
        <Navbar bg="white" expand="lg" className="p-2" fixed='top' collapseOnSelect>
            <Container fluid>
                <Navbar.Brand as={Link} to="/" className="fs-4">
                    <strong>H</strong>
                    <span className="text-danger">'</span>
                    <span className="text-muted">academy</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" navbarScroll activeKey={location.pathname}>
                        <Nav.Link as={Link} to="/portfolio">Portfolio</Nav.Link>
                        {/* <Nav.Link href="https://docs.sysout.co.kr" disabled={true}>Document</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/notice">Notice</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/quiz">Quiz</Nav.Link> */}
                        <Nav.Link as={Link} to="/link">Link</Nav.Link>
                    </Nav>
                    <Nav className="d-flex">
                        <LoginNavLink />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default MainHeader;